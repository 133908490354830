<template>
    <v-card flat class="">
        <v-card-text class="text-center">
            <h3>SELAMAT DATANG!</h3>
            <p>
                Selamat menempuh program Pelatihan di wilayah RSUD dr Soeselo Kabupaten Tegal. Kanal ini merupakan
                layanan
                pengaduan bagi peserta pelatihan selama mengikuti proses kegiatan pembelajaran di lingkungan RSUD dr
                Soeselo
                Kabupaten Tegal. Mohon kesediaan saudara/i melaporkan setiap kendala, pelanggaran, atau hal-hal lain
                yang
                tidak sesuai selama kegiatan pelatihan berlangsung.
            </p>
            <h4>KAMI MENJAMIN KERAHASIAAN IDENTITAS ANDA!</h4>
            <ul style="display: flex; justify-content: center;">
                <li>
                    <a target="_blank"
                        href="https://api.whatsapp.com/send/?phone=6285600206389&amp;text&amp;type=phone_number&amp;app_absent=0">
                        <v-img :src="require('@/assets/images/logos/whatasapp.png')" width="48" alt="logo"
                            style="margin: auto;" contain></v-img>
                        <p>WhatsApp</p>
                    </a>
                </li>

                <li>
                    <a target="_blank" href="mailto:diklatrsudsoeselo@gmail.com">
                        <v-img :src="require('@/assets/images/logos/mail.png')" width="48" alt="logo"
                            style="margin: 8px auto;" contain></v-img>
                        <p>Mail</p>
                    </a>
                </li>
            </ul>
        </v-card-text>
    </v-card>
</template>

<style scoped>
ul li {
    display: inline-block;
    padding: 10px;
}

ul li a {
    text-decoration: none;
}
</style>
<script>
export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() {
    },
    methods: {
    },
}
</script>